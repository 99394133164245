.navbardiv {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #ffff;
    color: black;
    justify-content: space-between;
    font-weight: 300;
}

#right-bar {
    display: flex;
    justify-content: space-evenly;
    height: 4vw;
}

.left-bar-link {
    display: flex;
    flex-direction: vertical;
    justify-content: center;
    text-transform: none;
    text-decoration: none;
    color: black;
    padding: 0px 20px;
}

.left-bar-link:hover {
    color: #588157;
}

#left-bar-logo-name {
    font-size: 1.4vw;
    margin-left: 10px;
}

.right-bar-name {
    font-size: 0.9vw;

}

.right-bar-link {
    display: flex;
    flex-direction: vertical;
    justify-content: center;
    color: #588157;
    padding: 9px 20px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    font-size: 18px;
    font-family: inherit;
    font-weight: 800;
    cursor: pointer;
    border: none;
    background: none;
    text-transform: uppercase;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
}

.right-bar-link:focus,
.right-bar-link:hover {
    color: #588157;
}

.right-bar-link:focus:after,
.right-bar-link:hover:after {
    width: 100%;
    left: 0%;
}

.right-bar-link:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #588157;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
    font-family: inherit;
}

.right-bar-div {
    display: flex;
    flex-direction: vertical;
    justify-content: space-evenly;
    text-align: center;
}

.free-consultation {
    flex: 1 1 auto;
    margin-right: 20px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border-radius: 10px;
    background-color: #588157;
    font-family: inherit;
    font-weight: 800;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.free-consultation:hover {
    background-color: white;
    color: #588157;
    box-shadow: 0px 0px 0px 3px #588157 inset;
}

#right-bar-free-button-text {
    padding-top: 0vw;
}

#logo {
    height: 4vw;
}