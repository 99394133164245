  .about-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
  }

  .about-page-btn {
      flex: 1 1 auto;
      margin-right: 20px;
      margin-top: 8%;
      padding: 10px;
      text-align: center;
      text-transform: capitalize;
      transition: 0.5s;
      border-radius: 10px;
      background-color: #588157;
      font-size: 4.3ch;
      font-family: inherit;
      font-weight: 600;
      color: rgb(255, 255, 255);
      border: none;
  }

  .about-container h3 {
      font-size: 5.5vw;
      font-weight: 600;
  }

  .about-top-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 5vw;
      align-items: center;
  }

  .about-image-container {
      padding-left: 4vw;
      display: flex;
      flex-direction: column;
      width: 40%;
  }

  .about-image-container img {
      max-width: 800px;
  }

  .about-us-text {
      margin-left: 3vw;
      text-align: left;
      width: 50%;
      font-size: 1.8vw;
      line-height: 2vw;
  }

  .more-details h2 {
      font-size: 4vw;
      padding-left: 3vw;
      background-color: white;
      color: #3A5A40;
      margin-bottom: none;
      font-weight: 400;
  }

  .more-details h4 {
      font-size: 3vw;
      padding-left: 5vw;
      font-weight: 300;
  }

  .more-details p {
      font-size: 1.8vw;
      font-weight: 300;
      line-height: 2.2vw;
  }

  .more-details img {
      width: 35%;
      filter: grayscale();
  }

  .section1,
  .section3 {
      background-color: #eeee;
      padding-top: 1vw;
      padding-bottom: 1vw;
      font-weight: 600;
  }

  .section2,
  .section5 {
      font-weight: 600;
  }

  .section-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2%;
      margin-left: 3%;
      margin-right: 5%;
  }