.container-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 400px;
    background-color: #3A5A40;
}


form {
    width: 40%;
    padding-left: 50px;
    padding-right: 50px;
    background-color: white;
    border-radius: 40px;
}

textarea {
    border: none;
    padding: 8px;
    margin: 0;
    box-sizing: border-box;
    border: 2px solid #333;
    background-color: white;
    border-radius: 10px;
    width: 100%;
}

input {
    border: 2px solid #3A5A40;
    border-radius: 20px;
    padding: 8px;
}

select {
    border: 2px solid #3A5A40;
    border-radius: 20px;
    padding: 10px;
}

#email {
    width: 100%;
}

#name-input-div {
    display: flex;
    justify-content: space-between;
}

.name-input {
    width: 45%;
}

#budget-input {
    width: 100%;
}

#submit-button {
    border: 2px solid #3A5A40;
    border-radius: 20px;
    padding: 8px;
    background-color: #3A5A40;
    color: white;
    width: 30%;
}

#submit-button-div {
    padding: 37px;
    display: flex;
    justify-content: space-around;
}

.contact-page-title {
    color: rgb(255, 255, 255);
    font-size: 50px;
}

@media only screen and (max-width: 700px) {
    .container-parent {
        background-color: white;
    }

    .contact-page-title {
        color: #3A5A40;
        font-size: 9vw;
    }

    .contact-form {
        width: 230px;
        padding-left: 10px;
    }

    .submit-button {
        min-width: 230px;
    }
}

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}