.contact-input {
    min-height: 25px;
    border-radius: 30px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

#name {}

#email {}

#message {}